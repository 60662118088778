import React from "react";
import { Dialog, DialogHeader } from "@rent_avail/dialog";
import Tag from "@rent_avail/tag";
import { Heading, Text } from "@rent_avail/typography";
import { Box, Card, Flex, Stack } from "@rent_avail/core";
import { lightGray700 } from "theme";
import usePromotedListingBundleSetting from "components/hooks/usePromotedListingBundleSetting";
import RealtorLogo from "../../../../../../assets/listing-logos/realtor.svg";
import PromotedListingsHowItWorksList from "./components/PromotedListingsHowItWorksList";
import PromotedListingsResponsiveDialog from "./components/PromotedListingsResponsiveDialog";
import PromotedPartners from "../purchase/PromotedPartners";
const PromotedListingsLearnMoreDialog = ({ open, toggle }) => {
    const { promotionFeatureEnabled } = usePromotedListingBundleSetting();
    return (React.createElement(Dialog, { open: open, toggle: toggle }, open && (React.createElement(PromotedListingsResponsiveDialog, { onClose: toggle },
        React.createElement(Flex, { sx: { justifyContent: "space-between", alignItems: "center", pb: "1rem" } },
            React.createElement(Tag, { bg: "blue_500", color: "white" }, "Beta"),
            React.createElement(DialogHeader, { sx: { m: 0, mb: "0!important", ml: "auto", svg: { color: lightGray700 } } })),
        React.createElement(Flex, { sx: {
                flex: "0 0 40%",
                flexDirection: ["column", "column", "row"],
                justifyContent: "space-between",
                gap: 2,
                pb: "1rem",
            } },
            React.createElement(Stack, { sx: { width: "100%" } },
                React.createElement(Heading, { as: "h2", id: "promoted-listings-learn-more-dialog-title", fontSize: "3rem", fontWeight: 400, lineHeight: "4rem" },
                    "Rent your place faster\u00A0with",
                    " ",
                    React.createElement(Box, { as: "span", sx: { color: "purple_700", fontWeight: 700 } }, "Promoted Listings")),
                React.createElement(Text, { pb: "1.5rem" }, "Get noticed when you spotlight your listing among the top search results."),
                React.createElement(Box, null,
                    React.createElement(Heading, { as: "h3", color: lightGray700, fontSize: "1.5rem", fontWeight: 100 }, "Promotion Partners"),
                    promotionFeatureEnabled ? (React.createElement(Box, { sx: { width: "100%", px: "2rem", my: "1rem" } },
                        React.createElement(PromotedPartners, null))) : (React.createElement(Box, { as: "img", sx: { height: "3rem", ml: "-0.5rem" }, src: RealtorLogo, alt: "Realtor.com logo" })))),
            React.createElement(Card, { sx: { flex: "0 0 60%", borderColor: "purple_700" } },
                React.createElement(Heading, { as: "h2", color: "black", fontSize: "2rem", fontWeight: 700, textAlign: "center", pb: "1.5rem" }, "How it works"),
                React.createElement(PromotedListingsHowItWorksList, null)))))));
};
export default PromotedListingsLearnMoreDialog;
