import ReactiveRecord, { Model } from "reactiverecord"
import { toInitials } from "utils"

class Applicant extends Model {
  static routes = {
    show: ":prefix/applications/:application_id/applicants/:id",
    update: ":prefix/applications/:application_id/applicants/:id",
  }

  static schema = {
    about_me: String,
    about_pets: String,
    application: Object,
    application_id: Number,
    authorized: Boolean,
    bankruptcy: Boolean,
    birth_date: String,
    certified_application: Boolean,
    complete: Boolean,
    credit_score: String,
    credit_score_color: String,
    current_income: String,
    current_income_explanation: String,
    display_name: String,
    email: String,
    employment_histories: Array,
    employment_histories_attributes: Array,
    eviction: Boolean,
    explanation: String,
    felony: Boolean,
    full_name: String,
    has_pets: Boolean,
    income_verifications: Array,
    income_verifications_attributes: Array,
    invoice_paid: Boolean,
    link_applicant_path: String,
    past_reporting_invoice_paid: Boolean,
    phone: String,
    profile: Boolean,
    refused_rent: Boolean,
    rental_histories: Array,
    rental_histories_attributes: Array,
    renter_profile: Object,
    revoked: Boolean,
    smoking: Boolean,
    status: String,
    shareable_submit_retries: Number,
    shareable_status: String,
    transunion_available: Boolean,
    transunion_credit_report_object_normalized: Object,
    transunion_criminal_records_object: Object,
    transunion_eviction_records_object: Object,
    transunion_exam_questions: Array,
    transunion_information_received: Boolean,
    transunion_reports_expired_at: String,
    transunion_reports_requested: Boolean,
    transunion_information_updated_at: String,
    screening_id: Number,
    user_attributes: Object,
    user_id: Number,
    user_images: Object,
    _timestamps: true,
  }

  get firstName() {
    return this.display_name.split(" ").first()
  }

  get initials() {
    return this.display_name::toInitials()
  }

  get indicatorColor() {
    return this.user_id ? "#3cbc8d" : undefined
  }

  get statusValue() {
    switch (this.status) {
      case "All steps complete":
        return 5
      case "Credit check available":
        return 4
      case "Rental form complete":
        return 3
      case "Rental form started":
        return 2
      default:
        /* Application requested */
        return 1
    }
  }

  get statusExplanation() {
    switch (this.status) {
      case "All steps complete":
        return "Applicant has finished application and authorized you to review"
      case "Credit check available":
        return "Applicant has authorized you to view TransUnion reports."
      case "Rental form complete":
        return "Applicant has completed all questions, but has not yet submitted the application."
      case "Rental form started":
        return "Applicant has signed up and started filling out your application"
      default:
        /* Application requested */
        return "Waiting for applicant to sign up for an account and accept your application"
    }
  }

  get lastActivity() {
    if (Object.isEmpty(this.renter_profile)) { return this.updatedAt.relative() }
    return new Date(this.renter_profile.updated_at).relative()
  }

  get landlordCanView() {
    return !!(this.complete && this.authorized && !this.revoked)
  }

  get certificationComplete() {
    return !!(this.complete && this.certified_application)
  }

  static statusMax = 5
}

export default ReactiveRecord.model("Applicant", Applicant)
