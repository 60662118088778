import React from "react";
import { Box, Flex } from "@rent_avail/core";
import { ListingPartnerWideLogos, REALTOR, ZUMPER, PADMAPPER, RENTDOT, REDFIN, APARTMENTSGUIDE, } from "../../config";
const PromotedPartners = () => (React.createElement(Flex, { sx: {
        flexDirection: "column",
        gap: "2rem",
        alignItems: "center",
        overflow: "hidden",
    } },
    React.createElement(Flex, { sx: {
            justifyContent: "space-evenly",
            width: "100%",
            gap: "1rem",
        } },
        React.createElement(Box, { as: "img", src: ListingPartnerWideLogos[REALTOR], alt: REALTOR, sx: { height: "2rem" } }),
        React.createElement(Box, { as: "img", src: ListingPartnerWideLogos[ZUMPER], alt: ZUMPER, sx: { height: "2rem" } })),
    React.createElement(Flex, { sx: {
            justifyContent: "space-evenly",
            width: "100%",
            gap: "1rem",
            overflow: "hidden",
        } },
        React.createElement(Box, { as: "img", src: ListingPartnerWideLogos[PADMAPPER], alt: PADMAPPER, sx: { height: "2rem", maxWidth: "30%" } }),
        React.createElement(Box, { as: "img", src: ListingPartnerWideLogos[RENTDOT], alt: RENTDOT, sx: { height: "2.2rem", maxWidth: "30%" } }),
        React.createElement(Box, { as: "img", src: ListingPartnerWideLogos[REDFIN], alt: REDFIN, sx: { height: "2rem", maxWidth: "30%" } })),
    React.createElement(Flex, { sx: {
            justifyContent: "center",
            width: "100%",
            gap: "1rem",
        } },
        React.createElement(Box, { as: "img", src: ListingPartnerWideLogos[APARTMENTSGUIDE], alt: APARTMENTSGUIDE, sx: { height: "2rem" } }))));
export default PromotedPartners;
