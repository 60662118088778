import { useGetMeQuery } from "gql/generated/graphql"
import { useMemo } from "react"
import { useSettings } from "../../settings"

/*
 * This hook is used to check if new promoted listing bundle feature is enabled
the setting will apply to 10% of LLs, and only those who have not purchased
premium syndications.
 */
const usePromotedListingBundleSetting = () => {
  const { promotedListingBundlePercent, promotedListingBundlePrice } = useSettings()
  const { data: { me } = {} } = useGetMeQuery()

  const partialsPercent = useMemo(() => {
    if (!me) {
      return 0
    }
    const value = Math.abs(Number(promotedListingBundlePercent || 0))
    return value > 100 ? 100 : value
  }, [me, promotedListingBundlePercent])
  const checkUserIdCovered = (userId) => {
    if (partialsPercent === 0) {
      return false
    }
    return !Math.floor(userId % (100 / partialsPercent))
  }
  const checkPromotionFeatureEnabled = () =>
    !!me &&
    checkUserIdCovered(+me.id) &&
    (!me.has_premium_syndications || me.has_premium_syndications_bundle)

  return {
    promotionFeatureEnabled: checkPromotionFeatureEnabled(),
    promotedListingBundlePrice,
  }
}

export default usePromotedListingBundleSetting
