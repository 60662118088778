import React from "react";
import { DollarSign } from "react-feather";
import { FieldDateInput, FieldInput, SelectInput } from "ui_components/FieldInput";
import { Col, McGrid } from "ui_components";
import { isNotNegativeNumber, required } from "validations";
import { Box } from "@rent_avail/core";
import { toDate } from "../../../context/graphql";
const LEASE_DURATION = {
    "1 Month": 1,
    "3 Months": 3,
    "6 Months": 6,
    "1 Year": 12,
    "2 Years": 24,
    "3 Years": 36,
    "4 Years": 48,
    "5 Years": 60,
};
const Terms = ({ attributes, handleChange }) => {
    const { rent, security_deposit: securityDeposit, move_in: moveIn, min_lease_length: minLeaseLength, move_in_fee: moveInFee, parking, } = attributes;
    const moveInDate = toDate(moveIn);
    return (React.createElement(Box, { className: "margin-2-tb" },
        React.createElement(Box, { as: "strong", className: "navy-blue" }, "Terms"),
        React.createElement(McGrid, null,
            React.createElement(Col, { sm: 6, className: "margin-1-t" },
                React.createElement(FieldInput, { onChange: (event) => handleChange({ rent: event.target.value }), labelText: "Rent Amount (Monthly)", name: "rent", validate: [required("Rent"), isNotNegativeNumber("Rent Amount")], initialValue: rent, icon: React.createElement(DollarSign, null), type: "number", required: true })),
            React.createElement(Col, { sm: 6, className: "margin-1-t" },
                React.createElement(FieldInput, { onChange: (event) => handleChange({ security_deposit: event.target.value }), labelText: "Security Deposit", name: "security_deposit", validate: isNotNegativeNumber("Security Deposit"), initialValue: !securityDeposit ? "0" : securityDeposit, icon: React.createElement(DollarSign, null), type: "number" }))),
        React.createElement(McGrid, null,
            React.createElement(Col, { sm: 6, className: "margin-1-t" },
                React.createElement(FieldDateInput, { labelText: "Date Available", name: "move_in", initialValue: moveInDate, validate: required("Date available"), onBlur: (event) => handleChange({ move_in: event.target.value }), required: true, datePicker: true })),
            React.createElement(Col, { sm: 6, className: "margin-1-t" },
                React.createElement(SelectInput, { onChange: (event) => handleChange({ min_lease_length: event.target.value }), labelText: "Lease Duration", name: "min_lease_length", initialValue: minLeaseLength },
                    React.createElement(Box, { as: "option", key: -1, value: "", disabled: true }, "-- Select --"),
                    Object.keys(LEASE_DURATION).map((duration) => (React.createElement(Box, { as: "option", key: duration, value: LEASE_DURATION[duration] }, duration)))))),
        React.createElement(McGrid, null,
            React.createElement(Col, { sm: 6, className: "margin-1-t" },
                React.createElement(FieldInput, { onChange: (event) => handleChange({ move_in_fee: event.target.value }), labelText: "Move In Fee", name: "move_in_fee", validate: isNotNegativeNumber("Move In Fee"), initialValue: !moveInFee ? "0" : moveInFee, icon: React.createElement(DollarSign, null), type: "number" })),
            React.createElement(Col, { sm: 6, className: "margin-1-t" },
                React.createElement(FieldInput, { onChange: (event) => handleChange({ parking: event.target.value }), labelText: "Parking Fee", name: "parking", validate: isNotNegativeNumber("Parking Fee"), initialValue: !parking ? "0" : parking, icon: React.createElement(DollarSign, null), type: "number" })))));
};
export default Terms;
