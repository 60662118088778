import { gql } from "@apollo/client"

const LANDLORD_BASE_FRAGMENT = gql`
  fragment LandlordBase on Landlord {
    activated_on
    account_adjustment_balance
    address_validated
    banned
    company_name
    company_phone
    created_at
    enable_premium_feature_callouts
    feature_flag_hash
    feedback
    free_trial_end_date
    has_pending_landlord_invitations
    has_premium_syndications
    has_premium_syndications_bundle
    is_premium_user
    maintenance_forward
    maintset
    next_invoice_due
    onboarding_opt_out
    phone2
    receive_application_update_email
    received_value_on
    receives_manual_bank_account_workaround
    referral_path
    referral_token
    require_transunion
    self_closed
    send_tenant_emails
    statefarm
    total_account_credit_received
    updated_at
    urgent_maintenance_phone

    ## User part
    id
    accepting_applications
    address1
    address2
    authorizations
    automatic_applicants_for_leads
    birth_date
    card_last_four
    city
    closed_account_feedback_options
    email
    email_guidance
    email_reminders
    email_notifications
    first_name
    has_old_credit_card
    identity_validated
    identity_verification_failed_at
    in_free_trial
    intent
    is_bank_add_blocked
    last_name
    last_onboarding_step
    last_stepper_feature
    last_stepper_step_name
    last_stepper_step_number
    last_stepper_step_url
    level
    name
    needs_to_add_payment_source
    next_billing_date
    next_invoice_amount
    phone
    phone_validated
    plan_discount
    portfolio_size
    profile_complete
    requested_fast_pay
    require_background_report
    require_credit_report
    require_eviction_report
    require_income_verification
    required_residence_history_years
    required_employment_history_years
    send_reference_request_emails
    state
    test_group
    tu_consent
    twilio_phone
    unit_count
    user_photo_medium_url
    user_photo_large_url
    valid_card
    validation_attempts_remaining
    zip
  }
`

export default LANDLORD_BASE_FRAGMENT
