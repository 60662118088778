import React, { useEffect } from "react";
import { ConfirmationDialog, FullscreenDialog } from "@rent_avail/dialog";
import { useMediaQuery } from "@rent_avail/utils";
import { Card, baseTheme } from "@rent_avail/core";
const PromotedListingsResponsiveDialog = ({ onClose, children, }) => {
    const isDesktop = useMediaQuery({ min: "40rem" });
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" || event.key === "Esc") {
                onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [onClose]);
    return isDesktop ? (React.createElement(ConfirmationDialog, { id: "promoted-listings-learn-more-dialog-id", "aria-labelledby": "promoted-listings-learn-more-dialog-title", "aria-modal": "true", scrimSx: {
            zIndex: 4,
            "& > aside": { maxWidth: "75rem", overflow: "hidden", p: 0 },
        } },
        React.createElement(Card, { sx: { backgroundColor: "purple_100" } }, children))) : (React.createElement(FullscreenDialog, { id: "promoted-listings-learn-more-dialog-id", "aria-labelledby": "promoted-listings-learn-more-dialog-title", "aria-modal": "true", style: {
            zIndex: 4,
            backgroundColor: baseTheme.colors.purple_100,
            padding: "2rem",
            height: "100vh",
        } }, children));
};
export default PromotedListingsResponsiveDialog;
