import React from "react"
import { Box } from "@rent_avail/layout"
import { Heading, Text } from "@rent_avail/typography"
import { supportEmail } from "imported-vars"
import ErrorPageWrapper from "./ErrorPageWrapper"

const PageNotFound = () => (
  <ErrorPageWrapper>
    <Heading as="h4">We’re sorry, this page cannot be found.</Heading>
    <Box sx={{ textAlign: "left"}}>
      <Text mt="1rem">
        If you think this is a mistake, please refer to the email you received or contact Avail at{" "}
        <Text fontWeight="800" as="span">
          (312) 292-9347
        </Text>{" "}
        or{" "}
        <Text fontWeight="800" as="span">
          {supportEmail}
        </Text>
        .
      </Text>
    </Box>
  </ErrorPageWrapper>
)

export default PageNotFound
